.sidemenu {
  flex: 0 0 100%;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  transition: 0.05s cubic-bezier(0.36, -0.01, 0, 0.77);
  height: 100%;
}

.sidemenu.closed {
  margin-left: -100%;
}
